import { getOriginName } from "../../../utils/utility";

const route = getOriginName();

const highAvailabilityFlag = () => {
    let flag;

    if (route.includes('pqa')) {
        flag = true;
    } else if (route.includes('qa')) {
        flag = true;
    } else if (route.includes('stg')) {
        flag = true;
    } else if (route.includes('preview')) {
        flag = true;
    } else {
        flag = true;
    }

    return flag;
};

const setCodeBigEnv = () => {
    let env;

    if (route.includes('pqa')) {
        env = '-trial';
    } else if (route.includes('qa')) {
        env = '-qa';
    } else if (route.includes('stg')) {
        env = '-stg';
    } else if (route.includes('preview')) {
        env = '-beta';
    } else {
        env = '-prod';
    }
    return `https://ecom-api${env}.codebig2.net`;
};

const setHighAvailabilityEnv = () => {
    let env = '.';
    let prod = '.np.';

    if (route.includes('pqa')) {
        env = '-pqa.';
    } else if (route.includes('qa')) {
        env = '-qa.';
    } else if (route.includes('stg')) {
        env = '-stg.';
    } else if (route.includes('preview')) {
        env = '-beta.';
        prod = '.';
    } else {
        prod = '.';
    }

    return `${env}ecom-gateway${prod}api-business.comcast.com`;
};

export const bseeCartDefaultEndpoint = () => {
    return  highAvailabilityFlag() ? `https://offer${setHighAvailabilityEnv()}/offer-api/v7/viewed` : `${setCodeBigEnv()}/offer-api/v7/viewed`;
}

export const bseeCustomerCacheEndpoint = () => {
    return  highAvailabilityFlag() ?  `https://customer${setHighAvailabilityEnv()}/customer-api/v7/customer/` : `${setCodeBigEnv()}/customer-api/v7/customer/`;
}
